import Swiper from 'swiper/bundle';
// import 'swiper/swiper-bundle.css';

let swiper = new Swiper('.main-page-top-slider .swiper-container', {
  loop: true,
  navigation: {
    nextEl: '.main-page-top-slider .swiper-button-next',
    prevEl: '.main-page-top-slider .swiper-button-prev',
  },
  pagination: {
    el: '.main-page-top-slider .swiper-pagination',
    clickable: true,
  },
});
