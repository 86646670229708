import Swiper from 'swiper/bundle';
// import 'swiper/swiper-bundle.css';

let swiper = new Swiper('.upcoming-courses .swiper-container', {
  slidesPerView: 'auto',
  spaceBetween: 26,
  loop: false,
  pagination: {
    el: '.upcoming-courses .swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.upcoming-courses .swiper-button-next',
    prevEl: '.upcoming-courses .swiper-button-prev',
  },
});



