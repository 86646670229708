import 'jquery';
import 'bootstrap';
import 'swiper';
import Inputmask from "inputmask";
import './lib/upcomingCourses';
import './lib/mainPageTopSlider';
import './lib/header';
import './lib/mainPageReviewsSlider';
import './lib/diagram';
import './lib/checkout'
import './lib/request'

let mediaQuery = x => {

    if (!x.matches) {
        $('.mydropdownjQuery').hover(function () {
                $(this).addClass('show');
                $(this).find('.dropdown-menu').addClass('show');
            },
            function () {
                $(this).removeClass('show');
                $(this).find('.dropdown-menu').removeClass('show');
            });
    }

    if (x.matches) {
        $('.dropdown-menu').addClass('show');

        $('.lang-switch .dropdown-menu').removeClass('show');
    }

    $('.lang-switch .mydropdownjQuery').click(function () {
            $(this).addClass('show');
            $(this).find('.dropdown-menu').toggleClass('show');
        })
}


let x = window.matchMedia("(max-width: 992px)")
mediaQuery(x)
x.addListener(mediaQuery)

let filterTitle = document.querySelectorAll('.filter-item-wrap .title')

if (filterTitle) {
    filterTitle.forEach(item => {
        item.addEventListener('click', () => {
            item.closest('.filter-item-wrap').classList.toggle('active-item')
        })
    })
}

Inputmask('+7 (999) 999-99-99').mask(document.getElementsByClassName('phone-mask'));
