$(document).ready(function() {
  
  let buy = function(publicId, title, price, data, success) {
    let widget = new cp.CloudPayments()
    widget.pay('auth', // или 'charge'
      { //options
        publicId, //id из личного кабинета pk_b430f8b23575e9942b71e7d2b3f49
        description: title,
        amount: price * 1, //сумма
        currency: 'KZT', //валюта
        skin: 'classic', //дизайн виджета (необязательно)
        data,
      },
      {
        onSuccess: function(options) { // success
          success()
          $.ajax({
            url: '/api/v1/app/pay',
            type: 'POST',
            data: { Data: data, Amount: price, },
            headers: {
              Accept: 'application/json',
            },
          })
        },
        onFail: function(reason, options) { // fail
          //действие при неуспешной оплате
        },
        onComplete: function(paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
          //например вызов вашей аналитики Facebook Pixel
        }
      }
    )
  }
  
  let check = function(data, success, error) {
    $.ajax({
      type: 'GET',
      url: '/api/v1/app/users',
      data,
      success: function(result) {
        success(result)
      },
      error: function(result) {
        error(result)
      },
      headers: {
        Accept: 'application/json',
      },
    })
  }
  let register = function(data, success, error) {
    $.ajax({
      url: '/api/v1/app/register',
      type: 'POST',
      data,
      success: function(result) {
        success(result)
      },
      error: function(result) {
        error(result)
      },
      headers: {
        Accept: 'application/json',
      },
    })
  }
  
  
  let afterRegister = false
  
  $('.pay-form').submit(function() {
    const form = $(this)
    form.find('.error-message').html('')
    form.find('input[type=submit]').attr('disabled', true)
    let type = form.find('input[name=type]').val()
    let value = form.find('input[name=value]').val()
    let email = form.find('input[name=email]').val()
    let title = form.find('input[name=title]').val()
    let price = form.find('input[name=price]').val()
    let publicId = form.find('input[name=publicId]').val()
    let partnerId = form.find('input[name=partnerId]').val()*1
    let _afterRegister = afterRegister
    afterRegister = false
    
    check({ email, value, type, },
      function(result) {
        if (result.data.isExist) {
          if (price == 0 && type == 'subscribe') {
            if (_afterRegister) {
              $('#success-modal-button').click()
              setTimeout(function() {
                window.open('https://uof.claned.com/', '_blank').focus()
              }, 1000)
            } else {
              form.find('.error-message').html('Вы уже зарегистрированы')
              return
            }
          } else {
            buy(publicId, title, price, { value, email, type, }, () => {
              if (type === 'course' && [1,2].includes(partnerId)) {
                $('#success-modal-button').click()
              } else {
                window.open('https://uof.claned.com/', '_blank').focus()
              }
            })
          }
          form.closest('.modal').find('button.close').click()
          form.find('input[name=email]').val('')
        } else {
          form.hide()
          form.next('form.register-form').show()
          form.next('form').find('input[name=email]').val(email)
        }
        form.find('input[type=submit]').attr('disabled', false)
      },
      function(result) {
        form.find('.error-message').html(JSON.parse(result.responseText).message)
        form.find('input[type=submit]').attr('disabled', false)
      })
    
    return false
  })
  
  $('.register-form').submit(function() {
    let email = $(this).find('input[name=email]').val()
    let firstName = $(this).find('input[name=firstName]').val()
    let lastName = $(this).find('input[name=lastName]').val()
    let password = $(this).find('input[name=password]').val()
    let partnerId = $(this).find('input[name=partnerId]').val()
    let phone = $(this).find('input[name=phone]').val()
    partnerId = partnerId ? partnerId * 1 : partnerId
    
    let data = {
      email,
      firstName,
      lastName,
      password,
      partnerId,
      phone,
    }
    
    const form = $(this)
    form.find('.error-message').html('')
    form.find('button[type=submit]').attr('disabled', true)
    
    register(data, function(request) {
      form.hide()
      afterRegister = true
      form.prev('form').show()
      form.prev('form').find('button[type=submit]').click()
      form.find('button[type=submit]').attr('disabled', false)
    }, function(result) {
      form.find('.error-message').html(JSON.parse(result.responseText).message)
      form.find('button[type=submit]').attr('disabled', false)
    })
    
    return false
  })
  
  
})
