let pie = document.querySelectorAll('.pie')
let beads = document.querySelectorAll('.beads circle')

if (pie) {
    let infoBlock = document.querySelectorAll('.info-item')

    pie.forEach(item => {
        let activeInfo = item.getAttribute('data-active-info')
        let activeR = item.getAttribute('data-r')
        item.addEventListener('mouseenter', (i) => {
            console.log(activeR)
            pie.forEach(pie => {
                pie.classList.remove('active')
            })

            beads.forEach( bead => {
                let beadId = bead.getAttribute('id')


                if(activeInfo == beadId){
                    console.log(beadId)
                    bead.setAttribute('r', activeR);
                } else {
                    bead.setAttribute('r', 11.5);
                }
            })

            item.classList.add('active')

            infoBlock.forEach(block => {
                let blockItem = block.getAttribute('id')
                if (activeInfo == blockItem) {
                    block.classList.add('show')
                } else {
                    block.classList.remove('show')
                }
            })
        })
    })
}
