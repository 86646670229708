$(document).ready(function() {
  let send = function(data, success, error) {
    $.ajax({
      type: 'POST',
      url: '/api/v1/app/request',
      data,
      headers: {
        Accept: 'application/json',
      },
      success: function(result) {
        success(result)
      },
      error: function(result) {
        error(result)
      },
    }).done(function (result) {
      done(result)
    })
  }
  
  $('.request-form').submit(function() {
    const form = $(this)
    const values = {};
    $.each(form.serializeArray(), function(i, field) {
      values[field.name] = field.value;
    });
    
    send(values, function() {
      form.trigger("reset")
      $('#success-modal-button').click()
      form.find('.error-message').html('')
    }, (result) => {
      form.find('.error-message').html(JSON.parse(result.responseText).message)
    })
    
    return false
  })
})
