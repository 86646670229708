import Swiper from 'swiper/bundle';
// import 'swiper/swiper-bundle.css';

let swiper = new Swiper('.reviews .swiper-container', {
  navigation: {
    nextEl: '.reviews .swiper-button-next',
    prevEl: '.reviews .swiper-button-prev',
  },
  pagination: {
    el: '.reviews .swiper-pagination',
    clickable: true,
  },
});
