let lastScrollTop = 0;
let BODY = document.querySelector('body')

let mediaQuery = x => {
    if (!x.matches) {
        window.onscroll = onScroll;

        function onScroll(e) {
            let top = window.pageYOffset;
            if (lastScrollTop > top) {
                BODY.classList.remove('hide-header')
                if (top < 100) {
                    BODY.classList.remove('blue-header')
                }
            } else if (lastScrollTop < top) {
                BODY.classList.add('hide-header')

                if (top > 300) {
                    BODY.classList.add('blue-header')
                }
            }
            lastScrollTop = top;
        }
    }
}

let menuBtn = document.querySelector('.menu-trigger')
let menuWrap = document.querySelector('body')

menuBtn.addEventListener('click', () => {
    menuWrap.classList.toggle('show-menu')
})

let x = window.matchMedia("(max-width: 992px)")
mediaQuery(x)
x.addListener(mediaQuery)


let filterBtn = document.querySelector('.mobile-filter-trigger')
let filter = document.querySelector('.filter-wrap-outside')
let closeFilter = document.querySelector('.close-filter')

if(filterBtn){
    filterBtn.addEventListener('click', () => {
        filter.classList.add('show-filter')
    })

    closeFilter.addEventListener('click', () => {
        filter.classList.remove('show-filter')
    })
}
